import React, { Component } from "react";

import stl from "./SingleQuest.module.css";
import { getFaqListOnloadData } from "../redux/actions/faqActions";
import { connect } from "react-redux";

class SingleQuest extends Component {
  constructor(props) {
    super(props);
  }
  render() {
    return (
      <>
        <div
          onClick={this.props.onClick}
          className={[
            "singleFaqContainer",
            this.props.showAns ? "expanded" : undefined,
            this.props.faqsPage ? stl.faqsPage : "",
            stl.StyleMod,
          ].join(" ")}
        >
          <strong>{this.props.qust}</strong>
          {this.props.showAns && <p>{this.props.ans}</p>}
        </div>
      </>
    );
  }
}

export default SingleQuest;
